// Named middleware for explicitly protected routes
// This is used when definePageMeta({middleware: 'auth'}) is specified

export default defineNuxtRouteMiddleware((to) => {
  const { isAuthenticated } = useAuth();
  
  // If user is not authenticated, store the intended path but allow page to render
  // The page itself will show login prompt via ClientOnly wrapper
  if (!isAuthenticated.value) {
    // Save the redirect path for after login
    useState('authRedirectPath', () => to.fullPath);
    console.log('Route middleware: Unauthorized access attempt to', to.fullPath);
    
    // Important: We DO NOT abort navigation or redirect
    // This allows the page to render, and the page itself will show the login prompt
    // based on the isAuthenticated state
    return;
  }
  
  // Allow navigation if authenticated
  return;
}); 